<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Memorial Generator</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select year</label>
              <select class="form-control" v-model="selectedYear">
                <option :value="ys" v-for="ys in years">{{ ys }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label><span class="mr-2">Enter periods</span> <a href="#" class="mr-2 text-info"
                                                                @click.prevent="addAllPeriods">add all periods</a> <a
                  href="#" @click.prevent="removeAllPeriods" class="mr-2 text-danger">remove all periods</a> </label>
              <b-form-tags
                  input-id="tags-pills"
                  v-model="periods"
                  tag-variant="primary"
                  tag-pills
                  :tag-validator="periodTagValidator"
                  size="lg"
                  separator=" "
                  placeholder="Enter period and hit enter"
              ></b-form-tags>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Select Grootboeks</label>
              <multiselect :options="rekSchsArr" :multiple="true" :show-labels="false" v-model="multiSelectOpt"
                           :hideSelected="true" :max="1000"
                           @select="selectOption"
                           @remove="removeOption"
                           track-by="index"
                           :custom-label="nameWithLang">


                <template slot="option" slot-scope="props">

                  <div v-if="props.option.index==-1" style="font-weight: bold; font-size:15px">
                    <div class="" v-if="!selectAll"><input type="radio"> Select all</div>
                    <div v-else> <input type="radio"> Deselect all</div>
                  </div>
                  <div v-else>
                    {{ props.option.value.record[0]['value'] }} - {{ props.option.value.record[2]['value'] }}
                    -->
                    ({{ props.option.value.selectedConfig.fieldName }})
                  </div>
                </template>

              </multiselect>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-success" @click="generateMemorialSheet"> Click to generate memorial sheet</button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <template v-if="tokens">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Generated Links <small class="text-danger">(download links are available to 10 mins for
            security purposes)</small></h4>
          <table class="table ">
            <!--            <template v-for="(links, token) in tokens">-->
            <!--              <tr>-->
            <!--                <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>-->
            <!--                <td><a :href="getDownloadUrl(token)"  @click="onDownloadLinkTapped">Click to download</a></td>-->
            <!--              </tr>-->
            <!--            </template>-->
            <template v-for="(links, token) in tokens">
              <template v-if="links[0]['status'] === 'failed'">
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at']) }}</td>
                  <td class="text-danger text-bold">{{ links[0]['status'] }}</td>
                  <td>{{ links[0]['status_description'] }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at']) }}</td>
                  <td class="text-success">{{ links[0]['status'] }}</td>
                  <td><a :href="getDownloadUrl(token)" @click="onDownloadLinkTapped">Click to download</a></td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import store from "../../../../store/store";
import Multiselect from "vue-multiselect";


export default {
  name: "generator",
  data() {
    return {
      selectedYear: null,
      selectAll: false,
      periods: [],
      years: [],
      selectedRekSchIndex: -1,
      selectedRekSchs: [],
      multiSelectOpt: []
    }
  },
  components: {
    Multiselect
  },

  computed: {
    tokens() {
      return this.$store.getters.GET_UNIT4_DOWNLOAD_TOKENS
    },


    rekSchs() {
      return this.$store.getters.GET_REK_SCHS;
    },
    rekSchsArr() {
      let obj = this.$store.getters.GET_REK_SCHS
      let item = Object.entries(obj).map((e) => ({index: e[0], value: e[1]}));
      item.unshift({index: -1, value: 'Select All'});
      return item;
    }
  },
  methods: {
    selectOption(val) {
      this.$nextTick(() => {
        if (val.index == -1) {
          this.selectAll = !this.selectAll;
          this.selectFnx(this.selectAll)
          return;
        }
        this.selectedRekSchs.push(val.value);
      });
    },
    removeOption(val) {
      this.$nextTick(() => {
        let ind = this.selectedRekSchs.indexOf(val);
        this.selectedRekSchs.splice(ind, 1);

      });
    },
    selectFnx(bool) {
      if (bool) {
        this.multiSelectOpt = this.rekSchsArr.filter((e) => e.index != -1);
      } else {
        this.multiSelectOpt = [];
      }
      this.selectedRekSchs = this.multiSelectOpt.map(e=>e.value);
    },
    nameWithLang(item) {
      if (item.index > 0) {
        return `${item.value.record[0]['value']} - ${item.value.record[2]['value']} --> (${item.value.selectedConfig.fieldName})`
      }
    },
    generateMemorialSheet() {
      const payload = {
        'year': this.selectedYear,
        'periods': this.periods,
        'grootboeks': this.selectedRekSchs
      }
      this.$store.dispatch('generateMemorialSheet', payload).then(() => {
        this.periods = [];
        this.selectedRekSchs = [];
      })
    },
    addAllPeriods() {
      this.periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    getUni4DownloadTokens() {
      this.$store.dispatch('getUni4DownloadTokens', 'memoriaal')
    },
    getDownloadUrl(token) {
      return process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/memoriaal/' + token
    },
    getDownloadDate(createdAt) {
      return moment(createdAt).calendar();
    },
    removeAllPeriods() {
      this.periods = [];
    },
    periodTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)) {
          return false
        } else if (!Number.isInteger(num)) {
          return false
        } else if (num < 1 || num > 12) {
          return false
        } else {
          return true;
        }
      } catch (e) {
      }

    },
    onDownloadLinkTapped() {
      setTimeout(() => {
        this.getUni4DownloadTokens()
      }, 2000)
    }
  }
  ,
  watch: {
    '$store.getters.GET_AVAILABLE_YEARS':
        function (ys) {
          this.years = ys;
          if (this.years.length === 1) {
            this.selectedYear = this.years[0]
          }
        },
  }
  ,
  created() {
    this.$store.dispatch('setPageTitle', 'Memoriaal')
    this.$store.dispatch('getAvailableYears');
    this.$store.dispatch('getRekSchBySheet', 'memoriaal');
    this.getUni4DownloadTokens()
  }
  ,
  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      console.log("OneSignal notification displayed:", event);
      this.getUni4DownloadTokens()
    });
  }
}
</script>

<style scoped>

</style>